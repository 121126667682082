import PLACE from "../../assets/image/place.svg";
import MONEY from "../../assets/image/money.svg";
import DEADLINE from "../../assets/image/deadline.svg";
import NEW from "../../assets/image/new.png";
import { useNavigate } from "react-router";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { compareDate } from "../../services/apiService/checkDate";
import { formatDate, numberWithCommas } from "../../utils/format/format";

export default function ItemListSearch({ data, current, index }) {
  const nagative = useNavigate();
  const handleRangeForm = (id) => {
    if (check_date) {
      nagative("/job/" + id);
    } else {
    }
  };
  const [check_date, set_check_date] = useState(true);
  useEffect(() => {
    let check = compareDate(
      data?.expire_date === "" ? "2060-10-31" : data?.expire_date
    );
    set_check_date(check);
  }, [data]);
  useEffect(() => {
    let check = compareDate(
      data?.expire_date === "" ? "2060-10-31" : data?.expire_date
    );
    set_check_date(check);
  }, []);

  return (
    <div
      onClick={() => handleRangeForm(data.meta_slug)}
      className="border-ani grid grid-cols-1 sm:grid-cols-2 m-2 list-border mb-7 hover:cursor-pointer"
    >
      <div className="col-span-1 m-2 ">
        <img
          src={data?.banner_url}
          className="sm:w-11/12 w-full lg:max-w-[300px]  rounded-lg"
        />
      </div>
      <div className="relative col-span-1">
        <div className="ml-5 sm:ml-0 sm:mt-5">
          <div className="font-regular text-[15px] uppercase">
            {data?.job_position}
          </div>
          <div className="relative">
            <div className="flex mt-2">
              <img src={PLACE} className="w-3" />
              <div className="ml-2 description-list font-book">
                {data?.location}
              </div>
            </div>
            <div className="flex mt-2">
              <img src={DEADLINE} className="w-3" />
              <div className="ml-2 description-list font-book">
                Hạn nộp:{" "}
                <span
                  style={check_date ? { color: "black" } : { color: "red" }}
                >
                  {data?.expire_date === ""
                    ? ""
                    : check_date
                    ? formatDate(data?.expire_date)
                    : "Hết hạn nộp"}
                </span>
              </div>
            </div>
            <div className="flex mt-2">
              <img src={MONEY} className="w-3" />
              <div className="ml-2 description-list font-book">
                {data.salary_to === 0
                  ? "Thương lượng"
                  : numberWithCommas(data.salary_from) +
                    " - " +
                    numberWithCommas(data.salary_to)}
              </div>
            </div>
            {index < 5 && current === 1 && (
              <Fragment>
                {data?.expire_date === "" ? (
                  ""
                ) : check_date ? (
                  <div className="absolute top-0 right-0 new-animation">
                    <img src={NEW} className="w-16" />
                  </div>
                ) : null}
              </Fragment>
            )}
          </div>
        </div>
        <div className="flex mt-3 justify-center md:mt-8 lg:mt-14 sm:justify-end">
          <button
            className="btn-ut  text-[15px] px-6 py-2 text-white rounded-3xl"
            style={
              check_date
                ? { backgroundColor: "#3AA3A4" }
                : { backgroundColor: "#b4b4b4" }
            }
            onClick={check_date ? () => handleRangeForm(data.meta_slug) : null}
          >
            Ứng tuyển
          </button>
        </div>
      </div>
    </div>
  );
}
