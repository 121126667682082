import FACE from "../../assets/image/facebook-share.png";
import NEW1 from "../../assets/image/news.webp";
import WORK from "../../assets/image/work.png";
import TT from "../../assets/image/mvcpro/thumbnail.png";
import NEW2 from "../../assets/image/news-2.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { jobServices } from "../../services/apiService/jobServices";

export default function NewsItem({ data }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleNewDetail = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-detail/" + id);
  };
  const handleNewDetails = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-details/" + id);
  };
  const [lstNew, setListNew] = useState();
  useEffect(() => {
    getLstNews(1, 3);
  }, []);
  const getLstNews = (page, limit) => {
    jobServices
      .listNew(page, limit)
      .then((res) => {
        console.log(res);
        setListNew(res?.data?.lst_news);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="w-[90%] sm:w-[65%] min-[1024px]:w-[90%] min-[1700px]:w-[55%] min-[1300px]:w-[73%] relative content-main">
      <div className="mt-6">
        <div className="flex justify-between">
          <div className="text-gray-500 font-book text-[12px]">
            {data?.create_date_article}
          </div>
          <div>
            <div className="w-20">
              <a
                href="https://www.facebook.com/share.php?u=https://www.facebook.com/mvcpro.vn"
                target="_blank"
              >
                <img src={FACE} className="w-20 cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 p-3 text-[13px] rounded-lg font-book shadow-grid">
        {data?.description}
      </div>
      <div
        className="mt-10 content w-full"
        dangerouslySetInnerHTML={{
          __html: data?.content?.replace(
            /\/web/g,
            "https://workspace.mvc.com.vn/web"
          ),
        }}
      ></div>
      <div className="mt-10"></div>

      <div className="flex mt-10">
        <div className="mr-2 flex items-center">
          <img src={WORK} className="w-5" />
        </div>
        <div className="font-regular text-[#3AA3A4] text-[24px]">
          BÀI VIẾT CÙNG CHUYÊN MỤC
        </div>
      </div>

      <div className="my-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {lstNew?.map((res) => (
          <div
            className="col-span-1 p-2 shadow-grid rounded-lg hover:cursor-pointer"
            onClick={() => handleNewDetail(res?.meta_slug)}
          >
            <img
              src={res?.image_thumbnail}
              className="rounded-md repon-img object-cover w-full max-h-[194px]"
            />
            <div className="text-[16px] text-reponsive mt-2 font-regular">
              {res?.title}
            </div>
            <p className="text-[14px] text-reponsive-news mt-2 font-book">
              {res?.description}
            </p>
            <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
              Xem thêm {">"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
