import Header from "../../components/Header";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import NewsItem from "./NewsItem";
import { dataRecruitmentNews } from "../../utils/data";
import { jobServices } from "../../services/apiService/jobServices";

export default function NewDetails() {
  const { id } = useParams();
  const [detailsNews, setDetailsNews] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(id);
    jobServices
      .detailsNew(id)
      .then((res) => {
        console.log(res);
        setDetailsNews(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return (
    <div>
      <div className="lg:mt-20 mt-14">
        <Header title={detailsNews[0]?.title} />
      </div>
      <NewsItem data={detailsNews[0]} />
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
