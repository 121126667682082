import { useNavigate } from "react-router-dom";
import BD from "../../../assets/image/bd1.jpg";
import BD3 from "../../../assets/image/bd3.jpg";
import WORK from "../../../assets/image/work.png";
import TT from "../../../assets/image/mvcpro/thumbnail.png";
import TT1 from "../../../assets/image/tt1.png";
import NEW1 from "../../../assets/image/new2.webp";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../services/apiService/configURL";
import { jobServices } from "../../../services/apiService/jobServices";

export default function NewsMainList() {
  const navigate = useNavigate();
  const handleNewDetail = (id) => {
    navigate("/news-detail/" + id);
  };
  const handleNewDetails = (id) => {
    navigate("/news-details/" + id);
  };
  const [lstNew, setListNew] = useState();
  const [lstNew5, setListNew5] = useState();
  const [limit, setLimit] = useState(0);
  useEffect(() => {
    getLstNews(1, 2);
    getLstNews(1, 5);
  }, []);
  const getLstNews = (page, limit) => {
    const a = jobServices
      .listNew(page, limit)
      .then((res) => {
        console.log(res);
        if (limit === 5) {
          setListNew5(res?.data?.lst_news);
        } else {
          setListNew(res?.data?.lst_news);
        }

        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(a);
  };
  return (
    <div className="w-[90%] min-[1700px]:w-[55%] sm:w-[65%] min-[1024px]:w-[90%] min-[1300px]:w-[73%] relative content-main my-8">
      <div className="flex">
        <div className="mr-2 flex items-center">
          <img src={WORK} className="w-5" />
        </div>
        <div className=" font-regular text-[#3AA3A4] text-[24px]">
          CÁC TIN TỨC MỚI NHẤT
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {lstNew?.map((res) => (
          <div
            className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg border-ani"
            onClick={() => handleNewDetail(res?.meta_slug)}
          >
            <img
              src={res?.image_thumbnail}
              className="object-cover w-full rounded-md repon-img max-h-[230px]"
            />
            <div className="text-[15px] title-reponsive mt-2 font-regular">
              {res?.meta_title}
            </div>
            <p className="text-[14px] text-reponsive-news mt-2 font-book">
              {res?.description}
            </p>
            <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
              Xem thêm {">"}
            </div>
          </div>
        ))}

        <div className="min-[1024px]:col-span-1  min-[1024px]:grid min-[1024px]:grid-cols-1 min-[1024px]:gap-6 max-[1024px]:hidden">
          {lstNew5?.slice(-3)?.map((res) => (
            <div
              onClick={() => handleNewDetail(res?.meta_slug)}
              className="border-ani col-span-1 shadow-grid mb-2 rounded-lg hover:cursor-pointer"
            >
              <div className="p-2 grid grid-cols-12 w-full">
                <div className="col-span-4 w-full h-[100px]">
                  <img
                    src={res?.image_thumbnail}
                    className="w-[200px] object-cover h-full rounded-md"
                  />
                </div>
                <div className="px-2 col-span-8 justify-around">
                  <div className="text-[15px] font-regular title-reponsive">
                    {res?.title}
                  </div>
                  <div className="text-[13px] font-book text-[#13a5a5]">
                    Xem thêm {">"}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-8 min-[1024px]:hidden">
        {lstNew5?.slice(-3)?.map((res) => (
          <div
            onClick={() => handleNewDetail(res?.meta_slug)}
            className="hover:cursor-pointer p-2 flex shadow-grid rounded-xl border-ani"
          >
            <div className="flex items-center w-[100px]">
              <img
                src={res?.image_thumbnail}
                className="h-28 w-[100px] object-cover max-w-[200px] rounded-lg"
              />
            </div>
            <div className="px-2 flex flex-col justify-around">
              <div className="text-[15px]  font-regular">{res?.title}</div>
              <div className="hover:cursor-pointer text-[13px] font-book text-[#13a5a5]">
                Xem thêm {">"}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
