import "./App.css";
import FormRegister from "./page/FormRegister/FormRegister";
import ContentRecruitment from "./page/ContentRecruitment/ContentRecruitment";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/HeaderNavBar/HeaderNavBar";
import PageMain from "./page/Main/PageMain";
import { QueryClient, QueryClientProvider } from "react-query";
import PageSearch from "./page/Main/PageSearch";
import ListJobSearch from "./page/ListJobSearch/ListJobSearch";
import RecruitmentNewsList from "./page/PageNewsEvents/RecruitmentNewsList";
import Contact from "./page/Contact/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewDetails from "./page/NewDetails/NewDetails";
import NewDetailsMVCPRO from "./page/NewDetails/NewDetailsMVCPRO";
function App() {
  const queryClient = new QueryClient();
  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <PageMain />
                </div>
              }
            />
            <Route
              path="/search"
              element={
                <div>
                  <PageSearch />
                </div>
              }
            />
            <Route
              path="/news-detail/:id"
              element={
                <div>
                  <Header />

                  <NewDetails />
                </div>
              }
            />
            {/* <Route
              path="/news-details/hoi-nghi-doi-moi"
              element={
                <div>
                  <Header />

                  <NewDetailsMVCPRO />
                </div>
              }
            /> */}
            <Route
              path="/list-jobs/:id"
              element={
                <div>
                  <ListJobSearch />
                </div>
              }
            />
            <Route
              path="/job/:id"
              element={
                <div>
                  <Header />
                  <ContentRecruitment />
                </div>
              }
            />
            <Route
              path="/contact"
              element={
                <div>
                  <Header />
                  <Contact />
                </div>
              }
            />
            <Route
              path="/second"
              element={
                <div>
                  <Header />
                  <ContentRecruitment />
                </div>
              }
            />
            <Route
              path="/recruitment-news"
              element={
                <div>
                  <Header />
                  <RecruitmentNewsList />
                </div>
              }
            />

            <Route
              path="/four"
              element={
                <div>
                  <Header />
                  <ContentRecruitment />
                </div>
              }
            />

            <Route
              path="/form-register/:id"
              element={
                <div>
                  <Header />
                  <FormRegister />
                </div>
              }
            />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
