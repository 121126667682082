import IMGPV from "../assets/image/im-pv.jpg";
import IMGTIMVIEC from "../assets/image/img-timviec.jpg";
import IMGSHARE1 from "../assets/image/IMG_1870.webp";
import IMGSHARE2 from "../assets/image/img-share2.jpg";
import IMGSHARE3 from "../assets/image/img-share3.jpg";
import TRAIN1 from "../assets/image/train1.jpg";
import TRAIN2 from "../assets/image/train2.jpg";
import TRAIN3 from "../assets/image/train3.jpg";
import TRAIN4 from "../assets/image/train4.jpg";
import DETAIL1 from "../assets/image/detail-1.png";
import DETAIL2 from "../assets/image/detail-2.png";
import DETAIL3 from "../assets/image/detail-3.png";
import DETAIL4 from "../assets/image/detail-4.png";
import DN from "../assets/image/dn.png";
import PP from "../assets/image/pp.png";
import GL from "../assets/image/gl.png";
import HS from "../assets/image/hs.png";
import PEPSICO from "../assets/image/pepsico.png";
import TTC from "../assets/image/ttc.png";
import FRISESLAND from "../assets/image/frisesland.png";
import P_G from "../assets/image/p&g.png";
import J_J from "../assets/image/j&j.png";
import FONTERA from "../assets/image/fontera.png";
import DANONE from "../assets/image/danone.png";
import ABINBEV from "../assets/image/abinbev.png";

import POP1 from "../assets/image/pop1.png";
import POP2 from "../assets/image/pop2.png";
import POP3 from "../assets/image/pop3.png";
import POP4 from "../assets/image/pop4.png";
import POP5 from "../assets/image/pop5.png";

export const dataRecruitmentNews = [
  {
    id: "hoi-nghi-doi-moi",
    title:
      "Hội Nghị Đổi Mới & Phát Triển 2024: Cột Mốc Gắn Kết Và Thúc Đẩy Đội Ngũ MVCPRO ",
    time: "Chủ nhật, 18 tháng 08, 2024",
    description:
      "CV của bạn gấy ấn tượng với người tuyển dụng, bạn nhận được lời mời phỏng vấn và giờ bạn cần phải ghi điểm để có được công việc. Các cuộc phỏng vấn có thể khá đáng sợ, nhưng sự thành công cuối cùng có được lại là do sự chuẩn bị kỹ lưỡng, sự dễ mến và tự tin. Dưới đây là 11 mẹo hàng đầu sẽ giúp bạn làm được điều đó.",
    img: [IMGPV],
    content: [
      {
        content_title:
          "Hội Nghị Đổi Mới & Phát Triển 2024: Cột Mốc Gắn Kết Và Thúc Đẩy Đội Ngũ MVCPRO ",
        content_description:
          "Trong hai ngày 16 và 17/08/2024, MVCPRO đã tổ chức thành công Hội nghị Đổi mới & Phát triển, sự kiện đánh dấu cột mốc quan trọng trong hành trình gắn kết và nâng tầm đội ngũ công ty. Hội nghị thu hút 143 nhân viên thuộc khối dự án RO (Retail Operations) với hàng loạt hoạt động ý nghĩa như lắng nghe chia sẻ truyền cảm hứng từ lãnh đạo công ty, đào tạo và giao lưu. ",
      },
      {
        content_title:
          "Đổi mới mỗi ngày, phát triển không ngừng & cùng nhau vươn xa ",
        content_description:
          "Với chủ đề “Đổi mới & Phát triển”, hội nghị được tổ chức nhằm thúc đẩy tư duy sáng tạo, tăng cường kết nối và khơi dậy động lực phát triển cho đội ngũ nhân sự của MVCPRO.  Đây cũng là dịp để toàn thể nhân viên cùng nhìn lại chặng đường đã qua để càng thêm gắn bó với công ty hơn và nỗ lực trau dồi, thích ứng với những thay đổi của thực tiễn, tạo tiền đề cho sự đột phá trong tương lai. ",
      },
      {
        content_title: "Những hoạt động nổi bật ",
        content_description:
          "Hội nghị mở đầu bằng các phiên chia sẻ về định hướng chiến lược năm mới từ Ban Lãnh đạo MVCPRO, nhằm giúp đội ngũ nắm bắt mục tiêu và chiến lược chung của công ty.  Tiếp theo là chuỗi các hoạt động đào tạo tập trung vào những chủ đề cốt lõi, cung cấp kiến thức và kỹ năng giúp nhân viên nâng cao hiệu quả công việc. Từ đó, mỗi nhân viên sẽ có những định hướng phát triển cho sự nghiệp của bản thân trong tương lai.  Tối ngày đầu tiên, chương trình Gala Dinner đã diễn ra trong không khí sôi động, gắn kết. Đây không chỉ là dịp để mọi người giao lưu, giải trí mà còn là cơ hội để vinh danh những đóng góp nổi bật của các cá nhân và tập thể. Bước sang ngày thứ hai, đội ngũ MVCPRO cùng tham gia các hoạt động Team Building sáng tạo và đầy thử thách. Các trò chơi được thiết kế nhằm khơi dậy tinh thần đồng đội, kỹ năng phối hợp và sự sáng tạo của từng thành viên. Những tiếng cười, cái siết tay thật chặt và những khoảnh khắc đáng nhớ đã trở thành dấu ấn không thể quên trong chương trình. Hội nghị Đổi mới & Phát triển đã khẳng định cam kết của MVCPRO trong việc đầu tư vào đội ngũ nhân sự, đặt trọng tâm vào sự đổi mới, đoàn kết và phát triển bền vững. Tinh thần năng động, tích cực từ hội nghị sẽ là nguồn động lực to lớn để tập thể MVCPRO cùng nhau chinh phục những thành công mới trong những năm về sau. ",
      },
    ],
  },
  {
    id: "meo-giup-de-dang-trung-tuyen-phong-van",
    title: "5 mẹo để giúp bạn dễ dàng trúng tuyển phỏng vấn ",
    time: "Thứ hai, 4 tháng 7, 2022",
    description:
      "CV của bạn gấy ấn tượng với người tuyển dụng, bạn nhận được lời mời phỏng vấn và giờ bạn cần phải ghi điểm để có được công việc. Các cuộc phỏng vấn có thể khá đáng sợ, nhưng sự thành công cuối cùng có được lại là do sự chuẩn bị kỹ lưỡng, sự dễ mến và tự tin. Dưới đây là 11 mẹo hàng đầu sẽ giúp bạn làm được điều đó.",
    img: [IMGPV],
    content: [
      {
        content_title: "1. THÊM KIẾN THỨC – THÊM TỰ TIN ",
        content_description:
          "Bạn đã bắt đầu quá trình nghiên cứu với một bộ hồ sơ tốt, và giờ là lúc để bắt đầu đặt cược: Tìm hiểu thêm về chức năng nhiệm vụ, những thành tựu và sự kiện quan trọng của công ty. Bạn cũng cần đọc các thông tin trên các kênh truyền thông xã hội song song với tìm hiểu thông tin về ngành nghề, sự cạnh tranh và người sẽ phỏng vấn bạn. Bạn càng biết nhiều, bạn càng cảm thấy tự tin. ",
      },
      {
        content_title: "2. TRANG PHỤC PHÙ HỢP ",
        content_description:
          "Quần áo bạn lựa chọn mặc đi phỏng vấn phải trông thật chuyên nghiệp, hãy thoải mái và bạn sẽ cảm thấy tự tin hơn. Tìm hiểu về văn hóa của công ty và cách mọi người ăn mặc trước khi quyết định mặc gì (có thể mặc vét khi phỏng vấn tại ngân hàng, hay mặc những bộ quần áo thông thường khi tới các công ty quảng cáo v.v…). Và hãy nhớ rằng nếu bạn chưa bao giờ mặc vét và muốn mặc tới dự phỏng vấn, hãy luyện tập trước một chút (bạn có thể cảm thấy khó chịu và vì vậy trông bạn cũng sẽ không thoải mái). Đừng quên đánh bóng giày của bạn và đảm bảo rằng không có vết rộp nào trên giầy khi bạn ra khỏi nhà. ",
      },
      {
        content_title: "3. CHUẨN BỊ KỸ CÁC CÂU HỎI KHỞI ĐỘNG ",
        content_description:
          "Có thể cược rằng bạn chắc chắn sẽ phải nói đôi điều với người phỏng vấn về bản thân bạn, lý do bạn nên được tuyển dụng và những mục tiêu nghề nghiệp của bạn. Hãy luyện tập trước các câu trả lời nhưng đừng có vẻ như cố gắng học thuộc. Đừng chỉ ghi nhớ những thông tin trong CV của bạn và gần như đọc hết ra khi được hỏi về bản thân. Sẽ là thông minh nếu bạn chỉ tham khảo những thông tin đó vì có vẻ như là người phỏng vấn đã có một bản thông tin đó trước mặt rồi, chỉ nhắc tới những sự kiện hay những điểm chính khi cần thiết, và đảm bảo bạn luôn thêm những câu chuyện thú vị về những thông tin đã nêu trong CV của bạn. ",
      },
      {
        content_title: "4… VÀ SẴN SÀNG CHO NHỮNG CÂU HỎI KHÓ ",
        content_description:
          "Sao bạn không nói cho tôi biết về những điểm yếu của bạn nhỉ?  Đây là cách mà bạn sẽ ghi điểm với những câu hỏi khó như vậy: Chọn lấy một điểm yếu của bản thân và khéo léo biến nó thành một điểm mạnh liên quan đến công việc. “Tôi là một người hơi thiếu kiên nhẫn, chỉ đơn giản là vì tôi muốn hoàn thành công việc đúng hạn và không làm ảnh hưởng tới công việc của cả nhóm.” Điều quan trọng là phải trung thực và đừng bao giờ trả lời rằng: “Tôi không có điểm yếu nào cả”. ",
      },
      {
        content_title:
          "5. CHUẨN BỊ SẴN SÀNG ĐỐI PHÓ VỚI NHỮNG CÂU HỎI HÓC BÚA ",
        content_description:
          "Hãy tưởng tượng bạn là một dụng cụ làm bếp, vậy bạn sẽ là loại dụng cụ nào và tại sao?  Những câu như vậy không thường xuyên được hỏi, nhưng nếu có, hãy cố gắng thoải mái và tự tin khi trả lời. Đó là những câu hỏi để kiểm tra tư duy phản biện và khả năng tự vận động suy nghĩ của bạn. Hãy đảm bảo nhấn mạnh cá tính của bạn khi trả lời và khiến câu trả lời của bạn trở nên vui vẻ và thú vị (tất nhiên là phải thích hợp nữa). Và cho câu hỏi về dụng cụ nhà bếp? Bạn có thể cân nhắc trả lời: Tôi là dụng cụ mở hộp.Thậm chí mặc dù đó không phải là loại dụng cụ quan trọng đầu tiên trong bếp, nó thật sự là một dụng cụ cần thiết cho mỗi bữa ăn. ",
      },
    ],
  },
  {
    id: "dau-la-cach-lam-viec-nhanh-va-hieu-qua",
    title: "Đâu là cách làm việc nhanh và hiệu quả?",
    time: "",
    description:
      "Rất nhiều người quan niệm rằng phải đầu tư thật nhiều thời gian để đạt năng suất cao trong công việc. Nhưng việc đó có còn ý nghĩ nếu nó không mang lại kết quả vượt trội như mong muốn? Thực tế, làm việc thông minh có nghĩa là đạt được kết quả tốt nhất trong thời gian và công sức ít bị lãng phí nhất. Nó không chỉ gói gọn trong số lượng đầu việc phải quản lý hoặc lượng thời gian cần bỏ ra để hoàn thành công việc đó. Cùng MVCPRO áp dụng ngay 6 kỹ năng dưới đây để nâng cao hiệu quả công việc: ",
    img: [IMGTIMVIEC],
    content: [
      {
        content_title: "1/ Ưu tiên công việc quan trọng ",
        content_description:
          "Cần sắp xếp độ ưu tiên dựa trên khối lượng công việc liên quan và thời hạn hoàn thành công việc đó. Đảm bảo dành đủ thời gian cho từng công việc và quy định thời hạn như một thách thức để hoàn thành công việc nhanh hơn. Thay vì làm nhiều việc cùng một lúc, nên tập trung giải quyết từng việc một nhằm nâng cao chất lượng kết quả. Khi hết thời hạn hoàn thành công việc, hãy dành một ít thời gian nghỉ ngơi và chuyển các sang thực hiện một nhiệm vụ khác để duy trì hoạt động của bản thân.",
      },
      {
        content_title: "2/ Giữ không gian làm việc ngăn nắp ",
        content_description:
          "Để làm việc năng suất hơn, nên chắc rằng mọi thông tin có thể truy cập dễ dàng và được sắp xếp ngăn nắp. Việc mất thời gian tìm kiếm hồ sơ chồng chất trên bàn sẽ khiến bạn trở nên mất tập trung. Không gian làm việc ngăn nắp sẽ giúp đầu óc tỉnh táo, tránh tình trạng lưu trữ hồ sơ không có hệ thống trên màn hình máy tính và bàn làm việc. Mỗi ngày, hãy dành một ít thời gian để gác lại công việc và dọn dẹp không gian để chuẩn tinh thần làm việc một cách tốt nhất. ",
      },
      {
        content_title: "3/ Giảm thiểu tác nhân gây phân tâm",
        content_description:
          "Hãy cố gắng giảm thiểu mọi yếu tố xung quanh dẫn đến sự phân tâm. Giảm tiếng chuông điện thoại di động và đem nó ra khỏi tầm nhìn khi làm việc. Đồng thời tắt tất cả các âm thanh chẳng hạn như chương trình truyền hình giải trí và bất kỳ bản nhạc gây mất tập trung. Thông báo rõ với đồng nghiệp để tránh bị làm phiền, nên giao tiếp trong thời gian nghỉ ngơi thay vì trong thời điểm đòi hỏi sự tập trung cao. ",
      },
      {
        content_title: "4/ Loại bỏ công việc không cần thiết ",
        content_description:
          "Thay vì mất tập trung và bận rộn vì những nhiệm vụ không mang lại kết quả tốt, nhà lãnh đạo nên lược bỏ công việc không cần thiết để nâng cao năng suất làm việc. Thiết lập thời hạn cho từng đầu việc mỗi ngày và ưu tiên dành thời gian cho các công việc quan trọng. ",
      },
      {
        content_title: "5/ Chia nhỏ công việc ",
        content_description:
          "Rất nhiều nhà lãnh đạo gặp tình trạng quá tải khi phải giải quyết một dự án lớn. Thay vì để công việc ngày càng tồn đọng, hãy chia nhỏ đầu việc và lên kế hoạch hoàn thành từng việc một. Hoàn thành từng đầu việc sẽ tạo cảm giác bản thân đang càng ngày đang đến gần hơn đến mục tiêu, nâng cao cảm hứng cho những công việc tiếp theo. ",
      },
      {
        content_title: "6/ Làm việc thông minh ",
        content_description: `Công việc thường xuyên bị kéo dài do mệt mỏi, mất tập trung, vô kế hoạch cho thấy dấu hiệu kém hiệu quả trong cách làm việc. Nỗ lực đầu tư nhiều công sức và thời gian để hoàn thành công việc chưa chắc sẽ mang lại kết quả tốt nhất. 
            Một người làm việc thông minh và hiệu quả là người biết cách ưu tiên công việc quan trọng và dành đủ thời gian để thực hiện nó một cách chính xác. Áp dụng thường xuyên thói quen hiệu quả vào công việc sẽ đem đến kết quả tích cực và vượt trội. Ngoài ra, lập kế hoạch công việc mỗi ngày là cần thiết để nâng cao hiệu suất làm việc. `,
      },
    ],
  },
  {
    id: "mvc-cung-pg-to-chuc-tap-huan-nhan-vien",
    title:
      "MVCPRO cùng P&G tổ chức đợt tập huấn cho Nhân Viên Bán Hàng kênh Siêu thị hiện đại",
    time: "",
    description:
      "MVCPRO hợp tác cùng P&G tổ chức chương trình tập huấn Nhân Viên Bán Hàng kênh Siêu thị hiện đại. Đợt training này diễn ra với sự tham gia sôi nổi của hơn 200 nhân viên bán hàng dưới sự chỉ đạo và huấn luyện của những chuyên gia hàng đầu trong lĩnh vực chốt Sales. Hơn 90% nhân viên tham gia đều cảm thấy tự tin hơn khi được trang bị thêm kiến thức và kĩ năng nghiệp vụ để hoàn thành tốt công việc của mình.",
    img: [IMGSHARE1, IMGSHARE2, IMGSHARE3],
    content: [
      {
        content_title: " ",
        content_description: "",
      },
    ],
  },
  {
    id: "mvc-gan-ket-nhan-vien-qua-hoat-dong-team-building",
    title:
      "MVCPRO gắn kết nhân viên thông qua hoạt động Team Building đầy hứng thú",
    time: "Thứ hai, 4 tháng 7, 2022",
    description:
      "Không phân biệt cấp bậc giữa lãnh đạo và nhân viên, không có khoảng cách giữa địa phương hay vùng miền, hơn 500 anh em nhân viên khối Siêu thị trên toàn quốc hội tụ và cùng tham gia chuyến đi team building. Kết nối để kiến tạo sức mạnh toàn năng - Làm hết sức và chơi hết mình. Đó là tinh thần mà đại gia đình MVCPRO luôn theo đuổi!",
    img: [DETAIL1, DETAIL2, DETAIL3, DETAIL4],
    content: [
      {
        content_title: " ",
        content_description: "",
      },
    ],
  },
  {
    id: "mvc-to-chuc-chuong-trinh-huan-luyen-cho-nhan-vien",
    title: "MVCPRO tổ chức chương trình huấn luyện cho Nhân viên bán hàng",
    time: "",
    description:
      "Những Nữ chiến binh bán hàng của MVCPRO đã có những  trải nghiệm tuyệt vời tại buổi Training Kỹ Năng đọc vị tâm lý khách hàng và chốt sale đỉnh cao. Hứa hẹn sẽ đem đến sự bùng nổ về doanh số bán hàng trong thời gian tới",
    img: [TRAIN1, TRAIN2, TRAIN3, TRAIN4],
    content: [
      {
        content_title: " ",
        content_description: "",
      },
    ],
  },
  {
    id: "nhung-dieu-nen-trang-bi-trong-nhung-nam-dau",
    title: "Những điều nên trang bị trong những năm đầu tiên đi làm ",
    time: "",
    description:
      "Khi các bạn có cơ hội tiếp xúc những người đi làm 1 vài năm, nếu các bạn hỏi họ “trường đại học có giúp ích nhiều cho công việc của anh/chị hay không?”, hầu hết câu trả lời mà các bạn nhận được sẽ là “không, rất ít, gần như không đáng kể” (trừ những ngành như y, kỹ thuật, thiết kế,...nói chung là những ngành đòi hỏi chuyên môn cao).",
    img: [IMGTIMVIEC],
    content: [
      {
        content_title: "1. KIẾN THỨC KẾ TOÁN - TÀI CHÍNH CƠ BẢN CHO CÁ NHÂN ",
        content_description: `Đi làm là tạo ra thu nhập, cũng đồng nghĩa với việc phải chi tiêu, chi trả cho những hóa đơn của mình. Cho dù mục đích tài chính của bạn trong từng tháng là gì (mua được bao nhiêu bộ quần áo, đi du lịch được những chỗ nào, gửi cho bố mẹ bao nhiêu tiền,..) bạn cũng cần có kế hoạch tài chính cơ bản để đạt được những mục tiêu đó: 
  
            Lập kế hoạch chi tiêu ra sao và mỗi tháng nên tiết kiệm bao nhiêu tiền? 
            Thuế là gì, có những khoản thuế nào chúng ta phải nộp và cách nộp thuế? 
            Bảo hiểm xã hội là gì và cơ chế vận hành của nó? Những loại bảo hiểm khác thì sao? 
            Mở tài khoản ngân hàng thế nào? Sự khác nhau giữa thẻ tín dụng (credit card) và thẻ ghi nợ (debit card)? Cần lưu ý gì nếu muốn đầu tư hoặc vay tiền? 
            
            Tiết kiệm và đầu tư là kỹ năng tài chính cơ bản mà chúng ta cần phải biết, để tránh tránh việc cuối tháng tự dưng thấy tài khoản không còn xu nào, hay đến lúc có cơ hội đầu tư thì cũng không có saving để thực hiện `,
      },
      {
        content_title: "2. CÁCH VIẾT CV, TÌM VIỆC, PHỎNG VẤN VÀ DEAL LƯƠNG",
        content_description: `Nên có những thông tin gì trong CV xin việc? Có những kênh tìm việc nào? 
            Có nên làm trái ngành đã học hay không? Cần lưu ý gì khi đi phỏng vấn? 
            Sinh viên mới ra trường thường được hưởng mức lương bao nhiêu? 
            Viết email và trả lời email sao cho chuyên nghiệp? 
            Thái độ phù hợp gắn bó với công ty 
            Và muôn vàn vấn đề to nhỏ khác 
            
            Đây là những câu hỏi mà chắc chắn các bạn đều luôn băn khoăn suy nghĩ trong đầu, nhưng không biết tìm lời giải đáp chuẩn ở đâu và cũng không biết liên hệ ai để cung cấp thông tin hay hỗ trợ mình. 
            
            Về cơ bản, đây là những kỹ năng cực kỳ quan trọng để chúng ta có thể “win” được job mà mình mong muốn, nhiều khi việc chúng ta không có được 1 công việc xịn hay 1 công việc mà mình yêu thích không phải là do chúng ta năng lực kém cỏi mà là do chúng ta 1) không có cơ hội tiếp xúc để ứng tuyển cho các công việc đó và 2) không biết cách thể hiện bản thân khi cơ hội tới `,
      },
      {
        content_title: "3. KỸ NĂNG TẠO DỰNG MỐI QUAN HỆ ",
        content_description:
          "Tri thức của các chuyên gia chỉ chiếm 15% trong thành công của họ, 85% còn lại phụ thuộc vào các mối quan hệ xã hội”- Andrew Carnegie , ông vua thép của Mỹ đã từng nói như vậy về việc tạo dựng mối quan hệ. Dẫn chứng ra vậy để các bạn hiểu việc tạo dựng các mối quan hệ là một phần cần thiết và quan trọng đến mức độ như thế nào trong công việc của chúng ta. Có mối quan hệ tốt cũng đồng nghĩa với việc có các nguồn lực hỗ trợ cả về tài chính và kiến thức tốt, và cũng đồng nghĩa là mình được tiếp xúc với nhiều cơ hội để phát triển bản thân hơn ",
      },
      {
        content_title: "4. QUẢN LÝ THỜI GIAN ",
        content_description:
          "Ước gì một ngày có 48 tiếng” - đó hẳn là câu các bạn sẽ phải thốt lên trong 2 năm đầu đi làm. Việc phải làm quen với guồng công việc 9 to 5, rồi lại các hoạt động và dự định cá nhân hẳn sẽ làm nhiều bạn thấy stressed. Do đó việc hoạch định thời gian một cách hiệu quả sẽ giúp chúng ta tối ưu được cả thời gian dành cho công việc và cá nhân, từ đó tạo ra những bứt phá. ",
      },
      {
        content_title: "5. THẤU HIỂU BẢN THÂN",
        content_description:
          "Sau vài năm đi làm các bạn sẽ thấy là “Oke la, giờ tôi đi làm for the sake of living” - tức là đi làm vì công ty trả lương và giúp tôi sống sót. Chúng ta không còn động lực để phấn đấu lên vị trí cao hơn, theo đuổi các nấc thang tiếp theo trong sự nghiệp. Việc này xảy ra là do chúng ta trong những năm đầu không xác định được bản thân mình là ai, cần gì và theo đuổi gì - dẫn đến chúng ta trôi nổi một thời gian dài và sau đó thì khi nhận thức được thì thấy “mình quá già để thay đổi rồi”. Do đó thấu hiểu bản thân hay biết bản thân là một trong những điều chúng ta cần lưu tâm mạnh mẽ trong giai đoạn đầu khi mới đi làm. ",
      },
    ],
  },
];
export const brand_name = [
  { img: DN },
  { img: GL },
  { img: PP },
  { img: HS },
  { img: PEPSICO },
  { img: TTC },
  { img: FRISESLAND },
  { img: P_G },
  { img: J_J },
  { img: FONTERA },
  { img: DANONE },
  { img: ABINBEV },
];
export const dataNews = [
  {
    img: POP1,
    title: "LƯƠNG THƯỞNG HẤP DẪN",
    content:
      "Lương cạnh tranh, thưởng hấp dẫn tùy theo vị trí công việc và thưởng thành tích hàng tháng, hàng quý và theo từng dự án",
  },
  {
    img: POP2,
    title: "PHÚC LỢI ĐẦY ĐỦ",
    content:
      "Được tham gia đầy đủ các chính sách phúc lợi theo luật quy định (bảo hiểm xã hội, bảo hiểm sức khỏe, chương trình khám sức khỏe tổng quát định kỳ theo quy định)​",
  },
  {
    img: POP3,
    title: "LỘ TRÌNH THĂNG TIẾN",
    content:
      "Lộ trình thăng tiến rõ ràng, được xét tăng lương, thăng tiến hàng năm​",
  },
  {
    img: POP4,
    title: "ĐÀO TẠO BÀI BẢN",
    content:
      "Được đào tạo kiến thức và kĩ năng chuyên môn bởi các chuyên gia dày dặn kinh nghiệm",
  },
  {
    img: POP5,
    title: "HOẠT ĐỘNG NGOẠI KHÓA",
    content:
      "Được tham gia các chương trình team building, du lịch,.. ​ của công ty hàng năm",
  },
];

export const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
