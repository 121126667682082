import { useEffect, useState } from "react";
import Header from "../../components/Header";
import TT from "../../assets/image/tt.png";
import TT1 from "../../assets/image/tt1.png";
import Footer from "../../components/FooterNavBar/FooterNavBar";
import ToggleSmooth from "../../components/NavSearch/togglesmooth/togglesmooth";
import { MetaTags } from "react-meta-tags";
import { useNavigate } from "react-router-dom";
import NEW1 from "../../assets/image/news.webp";
import MVCPRO from "../../assets/image/mvcpro/thumbnail.png";
import { jobServices } from "../../services/apiService/jobServices";
import { Pagination } from "antd";
let title = "TIN TỨC - SỰ KIỆN";
export default function RecruitmentNewsList() {
  const [current, setCurrent] = useState("1");
  useEffect(() => {
    window.scrollTo(0, 0);
    getLstNews(1, 3);
  }, []);
  const hanldeClick = (e, status_gifts) => {
    setCurrent(e.target.id);
  };
  const navigate = useNavigate();

  const handleNewDetail = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-detail/" + id);
  };
  const handleNewDetails = (id) => {
    window.scrollTo(0, 0);
    navigate("/news-details/" + id);
  };
  const [lstNew, setListNew] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [totalPage, setTotalPage] = useState(5);
  const getLstNews = (page, limit) => {
    jobServices
      .listNew(page, limit)
      .then((res) => {
        console.log(res);
        setTotalPage(res?.data?.page?.total_news);
        setListNew(res?.data?.lst_news);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleD = (data) => {
    console.log(data);
    setPage(data);
    getLstNews(data, limit);
  };
  return (
    <div className="lg:mt-20 mt-16">
      <MetaTags>
        <title>Tin tức - Sự kiện</title>
      </MetaTags>
      <Header title={title} />
      <div className="mt-10 flex flex-col justify-center items-center px-5">
        <div className="bg-tab tab flex justify-between items-center align-middle w-full max-w-[600px] mx-5 mb-5 bg-tab">
          <div className="flex justify-center flex-auto">
            <button
              key="1"
              id={1}
              disabled={current === `${1}`}
              onClick={(e) => hanldeClick(e, "done")}
              className="font-book  text-[13px]"
            >
              Tin tức mới
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="2"
              id={2}
              disabled={current === `${2}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[13px]"
            >
              Cuộc sống MVC
            </button>
          </div>
          <div className="flex justify-center flex-auto">
            <button
              key="3"
              id={3}
              disabled={current === `${3}`}
              onClick={(e) => hanldeClick(e, "not_yet")}
              className="font-book  text-[13px]"
            >
              Cẩm nang nghề nghiệp
            </button>
          </div>
        </div>
      </div>
      <div className="w-[90%] sm:w-[95%] min-[1024px]:w-[95%] min-[1300px]:w-[100%] relative content-main my-0">
        <div className="lg:mx-20 2xl:mx-56 sm:mx-20 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-5">
            {lstNew?.map((res) => (
              <div
                onClick={() => handleNewDetail(res?.meta_slug)}
                className="hover:cursor-pointer col-span-1 p-2 shadow-grid rounded-lg"
              >
                <img
                  src={res?.image_thumbnail}
                  className="rounded-md  object-cover w-full"
                />
                <div className="text-[16px] text-reponsive mt-2 font-regular">
                  {res?.title}{" "}
                </div>
                <p className="text-[14px] text-reponsive-news mt-2 font-book">
                  {res?.description}
                </p>
                <div className="hover:cursor-pointer text-[13px] mt-2 font-book text-[#3AA3A4]">
                  Xem thêm {">"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full mt-20 flex justify-center">
        <Pagination
          pageSize={3}
          current={page}
          total={totalPage}
          onChange={handleD}
        />
      </div>
      <div className="bg-footer mt-16">
        <Footer />
      </div>
      <ToggleSmooth />
    </div>
  );
}
